import React from "react"
import styled from "styled-components"

import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import Layout from "../components/layout"
import SEO from "../components/seo"
import GreenHeading from "../components/heading"
import device from "../components/device"

const Wrapper = styled.section`
  margin: 4rem 0 8rem 0;
  text-align: center;
  ${device.small`min-height: 30vh;`}
  .heading-wrapper {
    .heading {
      line-height: 1.2;
      ${device.small`display: block; line-height: 1; font-size: 2.5rem;`}
      .secondary-heading {
        font-size: 2rem;
        margin-left: 3rem;
        ${device.small`margin-left: 2rem; font-size: 1.5rem;`}
      }
    }
  }

  p {
    margin: 1rem 0;
  }

  .link-wrapper {
    margin: 2rem 0;
  }
  .link {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    background: var(--green);
    padding: 0.5rem 1.5rem;
  }

  .image-wrapper {
    margin: 2rem 0;
    display: inline-block;
    position: relative;
    .car-image {
      width: 20vmax;
      max-width: 400px;
    }
  }
`

const NotFoundPage = () => {
  const imgData = useStaticQuery(graphql`
    query {
      car: file(relativePath: { eq: "subpage/car.png" }) {
        childImageSharp {
          fluid(maxWidth: 450) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="404: Not found" />
      <Wrapper>
        <div className="heading-wrapper">
          <h1 className="heading">
            <GreenHeading size="6rem" text="Oops!" />
            <br />
            <span className="secondary-heading">Error: 404</span>
          </h1>
        </div>
        <p>We can't seem to find the page you're looking for.</p>

        <div className="image-wrapper">
          <Img
            fluid={imgData.car.childImageSharp.fluid}
            className="car-image"
          />
        </div>

        <div className="link-wrapper">
          <Link to="/" className="link">
            Go to home
          </Link>
        </div>
      </Wrapper>
    </Layout>
  )
}

export default NotFoundPage
